// tslint:disable
import {
  Component,
  ElementRef,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  DoCheck,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

import { CMInput } from "../../decorator";
import { CMGridEditConfigColumn } from "../../component";
import { CMFormComponent } from "../../component/cmform/cmform.component";
import {
  CMValidatorMinLength,
  CMValidatorMaxLength,
  CMValidatorRequired,
  CMValidatorDataType,
} from "./cminput-validator";
import { CMValidator } from "../../validator";

export class CMInputConfig {
  public dataType: string = "string";
  public caption: string | Function = "";
  getCaption() {
    let s = "";
    if (this.caption instanceof Function) s = this.caption();
    else s = this.caption;
    return s;
  }
  public padInfo = undefined;
  private innerRequired: boolean = false;
  get required() {
    let r = this.innerRequired;
    if (this.requiredFunc !== undefined) r = this.requiredFunc();
    return r;
  }
  set required(value: boolean) {
    this.innerRequired = value;
  }
  public requiredFunc: Function = undefined;
  private innerMaxLength: number = 0;
  public getMaxLength(gridRow) {
    let n = this.innerMaxLength;
    if (this.getMask(gridRow).length > 0) n = 0;
    return n;
  }
  public setMaxLength(value: number) {
    this.innerMaxLength = value;
  }

  public customvalidators = [];
  public criptografado: boolean = false;
  public readonly: boolean = false;
  public customStyle: string = "";
  public customStyleLabel: string = "";
  private InnerDisabled: boolean = false;
  public disabledFunc: Function = undefined;
  get disabled() {
    let r: boolean = this.InnerDisabled;
    if (this.disabledFunc !== undefined) r = this.disabledFunc();
    if (!r && this.form) {
      if (this.form.disabledAll !== undefined) r = this.form.disabledAll;
    }
    return r;
  }
  set disabled(value: boolean) {
    this.InnerDisabled = value;
  }
  public visibleFunc: Function = undefined;
  get visible() {
    let r: boolean = true;
    if (this.visibleFunc !== undefined) r = this.visibleFunc();
    return r;
  }
  public mask: string = "";
  public maskFunc: Function = undefined;
  public getMask(gridRow: any) {
    let m = "";
    if (this.mask.length > 0) m = this.mask;
    else if (this.maskFunc !== undefined) {
      let m2 = "";
      if (this.gridConfig) m2 = this.maskFunc(gridRow);
      else m2 = this.maskFunc();
      if (m2.length > 0) m = m2;
    }
    return m;
  }
  private innerMinLength: number = 0;
  public getMinLength(gridRow) {
    let n = this.innerMinLength;
    let m = this.getMask(gridRow);
    if (m.length > 0) n = m.length;
    return n;
  }
  public setMinLength(value: number) {
    this.innerMinLength = value;
  }
  public values = [];
  public valuesObject = [];
  public form: CMFormComponent = null;
  public components = [];
  private innerIconButton: string | Function = "";
  get iconButton() {
    let ico = this.innerIconButton;
    if (ico instanceof Function) ico = ico();
    if (ico === "") {
      if (this.pesqFunc !== undefined) ico = "search";
    }
    if (ico === "") {
      if (this.addFunc !== undefined) ico = "add";
    }
    return ico;
  }
  set iconButton(value: string | Function) {
    this.innerIconButton = value;
  }
  public hintButton: string = undefined;
  public pesqFunc: Function = undefined;
  public addFunc: Function = undefined;
  public pesqOnly: boolean = false;
  public pesqOnlyFunc: Function = undefined;
  public execPesqOnlyOnExit: boolean = true;
  public onExit: Function = undefined;
  public onBlur: Function = undefined;
  public onKeyPress: Function = undefined;
  public onKeyDown: Function = undefined;
  public onKeyUp: Function = undefined;
  public onEnter: Function = undefined;
  public onChange: Function = undefined;
  public onSetValue: Function = undefined;
  public getBottomLabelFunc: Function = undefined;
  public gridConfig: CMGridEditConfigColumn = undefined;
  public upper: boolean = false;
  public lower: boolean = false;
  public showButtonClear: boolean = true;
  public showButtonFunc: Function = undefined;
  dropdownValues: Object[] | Function;

  constructor(data?: any) {
    if (data) {
      if (data.form !== undefined) this.form = data.form;
      if (data.dataType !== undefined) this.dataType = data.dataType;
      if (data.caption !== undefined) this.caption = data.caption;
      if (data.required !== undefined) this.required = data.required;
      if (data.requiredFunc !== undefined)
        this.requiredFunc = data.requiredFunc;
      if (data.minlength !== undefined) this.innerMinLength = data.minlength;
      if (data.maxlength !== undefined) this.innerMaxLength = data.maxlength;
      if (data.criptografado !== undefined)
        this.criptografado = data.criptografado;
      if (data.customvalidators !== undefined)
        this.customvalidators = data.customvalidators;
      if (data.readonly !== undefined) this.readonly = data.readonly;
      if (data.customStyle !== undefined) this.customStyle = data.customStyle;
      if (data.customStyleLabel !== undefined) this.customStyleLabel = data.customStyleLabel;
      if (data.disabled !== undefined) this.disabled = data.disabled;
      if (data.disabledFunc !== undefined)
        this.disabledFunc = data.disabledFunc;
      if (data.visibleFunc !== undefined) this.visibleFunc = data.visibleFunc;
      if (data.mask !== undefined) this.mask = data.mask;
      if (data.maskFunc !== undefined) this.maskFunc = data.maskFunc;
      if (data.values !== undefined) this.values = data.values;
      if (data.valuesObject !== undefined)
        this.valuesObject = data.valuesObject;
      if (data.iconButton !== undefined) this.iconButton = data.iconButton;
      if (data.hintButton !== undefined) this.hintButton = data.hintButton;
      if (data.pesqFunc !== undefined) this.pesqFunc = data.pesqFunc;
      if (data.addFunc !== undefined) this.addFunc = data.addFunc;
      if (data.showButtonFunc !== undefined)
        this.showButtonFunc = data.showButtonFunc;
      if (data.pesqOnly !== undefined) this.pesqOnly = data.pesqOnly;
      if (data.pesqOnlyFunc !== undefined)
        this.pesqOnlyFunc = data.pesqOnlyFunc;
      if (data.padInfo !== undefined) this.padInfo = data.padInfo;
      if (data.onEnter !== undefined) this.onEnter = data.onEnter;
      if (data.onExit !== undefined) this.onExit = data.onExit;
      if (data.onBlur !== undefined) this.onBlur = data.onBlur;
      if (data.onKeyPress !== undefined) this.onKeyPress = data.onKeyPress;
      if (data.onKeyDown !== undefined) this.onKeyDown = data.onKeyDown;
      if (data.onKeyUp !== undefined) this.onKeyUp = data.onKeyUp;
      if (data.execPesqOnlyOnExit !== undefined)
        this.execPesqOnlyOnExit = data.execPesqOnlyOnExit;
      if (data.onChange !== undefined) this.onChange = data.onChange;
      if (data.getBottomLabelFunc !== undefined)
        this.getBottomLabelFunc = data.getBottomLabelFunc;
      if (data.gridConfig !== undefined) this.gridConfig = data.gridConfig;
      if (data.upper !== undefined) this.upper = data.upper;
      if (data.lower !== undefined) this.lower = data.lower;
      if (data.showButtonClear !== undefined)
        this.showButtonClear = data.showButtonClear;
      if (data.dropdownValues !== undefined)
        this.dropdownValues = data.dropdownValues;
      if (data.onSetValue !== undefined) this.onSetValue = data.onSetValue;
    }
    this.customvalidators.push(
      new CMValidatorRequired((r) => {
        return this;
      })
    );
    this.customvalidators.push(
      new CMValidatorMaxLength((r) => {
        return this;
      })
    );
    this.customvalidators.push(
      new CMValidatorDataType((r) => {
        return this;
      })
    );
    this.customvalidators.push(
      new CMValidatorMinLength((r) => {
        return this;
      })
    );
  }

  getData() {
    let data: any = {};
    if (this.form !== undefined) data.form = this.form;
    if (this.dataType !== undefined) data.thisType = this.dataType;
    if (this.caption !== undefined) data.caption = this.caption;
    if (this.required !== undefined) data.required = this.required;
    if (this.requiredFunc !== undefined) data.requiredFunc = this.requiredFunc;
    if (this.innerMinLength !== undefined) data.minlength = this.innerMinLength;
    if (this.innerMaxLength !== undefined) data.maxlength = this.innerMaxLength;
    if (this.criptografado !== undefined)
      data.criptografado = this.criptografado;
    if (this.customvalidators !== undefined)
      data.customvalidators = this.customvalidators;
    if (this.readonly !== undefined) data.readonly = this.readonly;
    if (this.customStyle !== undefined) data.customStyle = this.customStyle;
    if (this.customStyleLabel !== undefined) data.customStyleLabel = this.customStyleLabel;
    if (this.disabled !== undefined) data.disabled = this.disabled;
    if (this.disabledFunc !== undefined) data.disabledFunc = this.disabledFunc;
    if (this.visibleFunc !== undefined) data.visibleFunc = this.visibleFunc;
    if (this.mask !== undefined) data.mask = this.mask;
    if (this.maskFunc !== undefined) data.maskFunc = this.maskFunc;
    if (this.values !== undefined) data.values = this.values;
    if (this.valuesObject !== undefined) data.valuesObject = this.valuesObject;
    if (this.iconButton !== undefined) data.iconButton = this.iconButton;
    if (this.hintButton !== undefined) data.hintButton = this.hintButton;
    if (this.pesqFunc !== undefined) data.pesqFunc = this.pesqFunc;
    if (this.showButtonFunc !== undefined)
      data.showButtonFunc = this.showButtonFunc;
    if (this.pesqOnly !== undefined) data.pesqOnly = this.pesqOnly;
    if (this.pesqOnlyFunc !== undefined) data.pesqOnlyFunc = this.pesqOnlyFunc;
    if (this.padInfo !== undefined) data.padInfo = this.padInfo;
    if (this.onEnter !== undefined) data.onEnter = this.onEnter;
    if (this.onExit !== undefined) data.onExit = this.onExit;
    if (this.onBlur !== undefined) data.onBlur = this.onBlur;
    if (this.onKeyPress !== undefined) data.onKeyPress = this.onKeyPress;
    if (this.onKeyDown !== undefined) data.onKeyDown = this.onKeyDown;
    if (this.onKeyUp !== undefined) data.onKeyUp = this.onKeyUp;
    if (this.execPesqOnlyOnExit !== undefined)
      data.execPesqOnlyOnExit = this.execPesqOnlyOnExit;
    if (this.onChange !== undefined) data.onChange = this.onChange;
    if (this.getBottomLabelFunc !== undefined)
      data.getBottomLabelFunc = this.getBottomLabelFunc;
    if (this.gridConfig !== undefined) data.gridConfig = this.gridConfig;
    if (this.upper !== undefined) data.upper = this.upper;
    if (this.lower !== undefined) data.lower = this.lower;
    if (this.showButtonClear !== undefined)
      data.showButtonClear = this.showButtonClear;
    if (this.dropdownValues !== undefined)
      data.dropdownValues = this.dropdownValues;
    if (this.onSetValue !== undefined) data.onSetValue = this.onSetValue;
    return data;
  }

  clone() {
    return new CMInputConfig(this.getData());
  }

  static trimValue(value: any) {
    let result = "";
    if (value !== undefined && value !== null) {
      value = String(value);
      if (typeof value === "string" || value instanceof String) {
        result = value.trim();
      } else result = value;
    }
    return result;
  }

  static pad(
    value: string,
    size: number,
    char: string = " ",
    left: boolean = true
  ): string {
    if (char === undefined) char = " ";
    if (left === undefined) left = true;
    var s = value + "";
    while (s.length < size) {
      if (left) s = char + s;
      else s = s + char;
    }
    return s;
  }

  static valueToInteger(value: any, _default?: number) {
    value = CMInputConfig.trimValue(value);
    if (!/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) value = NaN;
    let num: number = parseInt(value);
    if (_default !== undefined) {
      if (isNaN(num)) num = _default;
    }
    return num;
  }

  static integerToValue(integer: number) {
    return CMInputConfig.arredondar(integer, 0);
  }

  static validDate(date: string) {
    let valido: boolean = date.length === 10;
    if (valido) {
      let arr = date.split("/");
      valido = arr.length === 3;
      if (valido) {
        let ano = CMInputConfig.valueToInteger(arr[2], 0);
        let mes = CMInputConfig.valueToInteger(arr[1], 0);
        let dia = CMInputConfig.valueToInteger(arr[0], 0);
        valido = ano > 0 && mes > 0 && dia > 0;
        if (valido) valido = mes > 0 && mes < 13;
        if (valido) {
          let dt = new Date(ano, mes - 1, dia);
          valido =
            dt.getFullYear() === ano &&
            dt.getMonth() === mes - 1 &&
            dt.getDate() === dia;
        }
      }
    }
    return valido;
  }

  static valueToFloat(value: any, _default?: number) {
    value = CMInputConfig.trimValue(value).replace(",", ".");
    if (!/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) value = NaN;
    let num: number = parseFloat(value);
    if (_default !== undefined) {
      if (isNaN(num)) num = _default;
    }
    return num;
  }

  static floatToValue(float: number, casasDecimais?: number) {
    return CMInputConfig.arredondar(float, casasDecimais);
  }

  static arredondar(value: number, casasDecimais?: number) {
    if (casasDecimais === undefined) casasDecimais = 2;
    return value.toFixed(casasDecimais).replace(".", ",");
  }

  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  valid(value: any, ignoreEmpty: boolean, gridRow) {
    if (this.disabled || this.readonly || !this.visible) return "";
    else if (ignoreEmpty && CMInputConfig.trimValue(value) === "") return "";
    else if (this.customvalidators) {
      for (var i = 0, len = this.customvalidators.length; i < len; ++i) {
        let validator: CMValidator = <CMValidator>this.customvalidators[i];
        let r = validator.validate(value, gridRow);
        if (r !== undefined) {
          return r;
        }
      }
    }
    return "";
  }

  isPesqOnly(row) {
    if (this.pesqOnlyFunc !== undefined) return this.pesqOnlyFunc(row);
    else return this.pesqOnly;
  }

  isDoubleField() {
    let r = false;
    if (this.dataType.startsWith("double") || this.dataType == "integer")
      r = true;
    return r;
  }

  get isFocused() {
    let r = false;
    if (this.components.length > 0) r = this.components[0].isFocused;
    return r;
  }

  focus() {
    if (this.components.length > 0) {
      this.components[0].focus();
    }
  }

  onClick() {
    if (this.components.length > 0) {
      this.components[0].onClick({});
    }
  }

  setValueInObject(target, targetProp, value) {
    target[targetProp] = this.valuesObject[value];
    if (!target[targetProp]) target[targetProp] = {};
  }
}

export const CMINPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CMInputComponent),
  multi: true,
};

export const CMInputComponent_props = [
  "inputConfig",
  "type",
  "caption",
  "visiblecaption",
  "name",
  "showPassword",
  "mask",
  "required",
  "minlength",
  "maxlength",
  "readonly",
  "disabled",
  "forceDisabled",
  "visible",
  "gridRow",
  "criptografado",
  "customvalidators",
  "value",
  "tag",
  "bottomLabel",
  "upper",
  "lower",
  "wordwrap",
  "widthcolumns",
  "useMaginTop",
  "labelEverActive",
  "autocomplete",
  "customStyle",
  "customStyleLabel",
];

export const CMOutputComponent_props = ["onChange", "onEnterInput"];

@Component({
  selector: "cminput",
  templateUrl: "./cminput.component.html",
  styleUrls: ["./cminput.component.scss"],
  providers: [CMINPUT_CONTROL_VALUE_ACCESSOR],
})
export class CMInputComponent implements ControlValueAccessor, DoCheck {
  @Input()
  @CMInput()
  useMaginTop: boolean = true;

  static globalId: number = 0;
  event: any;
  static nextGlobalId() {
    CMInputComponent.globalId += 1;
    return CMInputComponent.globalId;
  }

  innerElement: any = null;
  getElement() {
    let r: any = undefined;
    if (this.innerElement === null)
      this.innerElement = document.getElementById(this.name);
    if (this.innerElement !== null) r = this.innerElement;
    return r;
  }

  innerElementLabel: any = null;
  getElementLabel() {
    let r: any = undefined;
    if (this.innerElementLabel === null)
      this.innerElementLabel = document.getElementById("lb_" + this.name);
    if (this.innerElementLabel !== null) r = this.innerElementLabel;
    return r;
  }

  @Input()
  @CMInput()
  wordwrap: boolean = false;

  @Input()
  @CMInput()
  autocomplete: string = "true";

  getAutocomplete() {
    // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html
    if (this.autocomplete === "off" && this.criptografado) {
      return "new-password";
    } else {
      return this.autocomplete;
    }
  }

  @Input()
  @CMInput()
  widthcolumns: string = undefined;

  @Input()
  inputConfig: CMInputConfig = null;

  @Input()
  padInfo = undefined;

  @Input()
  type: string = "text";

  @Input()
  bottomLabel: string = "";

  @Input()
  caption: string | Function = "";

  @Input()
  visiblecaption: boolean = true;

  @Input()
  iconButton: string | Function = "";

  @Input()
  name: string = "cminput_" + CMInputComponent.nextGlobalId().toString();

  @Input()
  @CMInput()
  showPassword: boolean = false;

  innerUpper: boolean = false;
  get upper() {
    if (this.inputConfig) return this.inputConfig.upper;
    else return this.innerUpper;
  }
  @Input()
  set upper(value: boolean) {
    if (this.inputConfig) this.inputConfig.upper = value;
    else this.innerUpper = value;
  }

  innerLower: boolean = false;
  get lower() {
    if (this.inputConfig) return this.inputConfig.lower;
    else return this.innerLower;
  }
  @Input()
  set lower(value: boolean) {
    if (this.inputConfig) this.inputConfig.lower = value;
    else this.innerLower = value;
  }

  arrayMask = [];
  getArrayMask() {
    if (this.inputConfig) {
      let m = this.inputConfig.getMask(this.gridRow);
      if (m.length > 0) {
        if (this.mask !== m) this.mask = m;
      }
    }
    return this.arrayMask;
  }
  private internalMask: string = "";
  get mask() {
    return this.internalMask;
  }
  @Input()
  set mask(value: string) {
    if (value === undefined) value = "";
    if (this.internalMask !== value) {
      this.internalMask = value;
      this.arrayMask = [];
      for (let s of this.internalMask) {
        if (s === "L") this.arrayMask.push(/[A-Z]/i);
        else if (s === "A") this.arrayMask.push(/[A-Z, 0-9]/i);
        else if (s === "9") this.arrayMask.push(/[0-9]/);
        else this.arrayMask.push(s);
      }
    }
  }

  @Input()
  public gridRow = {};

  ngDoCheck() {
    if (!this.getReadOnly() && !this.disabled && this.visible) {
      if (this.arrayMask.length > 0) {
        let v = this.value;
        if (v && v.length > this.arrayMask.length) {
          v = v.substr(0, this.arrayMask.length);
          this.valueEdit = v;
        } else if (this.isFocused) {
          let element = this.getElement();
          if (element) {
            let ve = CMInputConfig.trimValue(this.valueEdit);
            let ev = CMInputConfig.trimValue(element.value);
            if (ve.length > 0 && ev.length > 0) {
              if (ve.length === ev.length) {
                if (element.value !== this.valueEdit)
                  this._setInnerValue(element.value);
              }
            }
          }
        }
      }
    }
  }

  private innerRequired: boolean = false;
  @Input()
  get required() {
    if (this.inputConfig) return this.inputConfig.required;
    else return this.innerRequired;
  }
  set required(value: boolean) {
    this.innerRequired = value;
  }

  @Input()
  minlength: number = 0;
  getMinLength() {
    if (this.inputConfig) return this.inputConfig.getMinLength(this.gridRow);
    else return this.minlength;
  }

  @Input()
  maxlength: number = 0;
  getMaxLength() {
    if (this.inputConfig) return this.inputConfig.getMaxLength(this.gridRow);
    else return this.maxlength;
  }

  @Input()
  @CMInput()
  readonly: boolean = false;

  getReadOnly() {
    let r: boolean = this.readonly;
    if (!r && this.inputConfig) r = this.inputConfig.isPesqOnly(this.gridRow);
    return r;
  }

  @Input()
  @CMInput()
  forceDisabled: boolean = false;

  @Input()
  @CMInput()
  disabled: boolean = false;
  getDisabled() {
    if (this.forceDisabled) return true;
    else if (this.inputConfig) {
      let r = this.inputConfig.disabled;
      if (
        !r &&
        this.inputConfig.gridConfig &&
        this.inputConfig.gridConfig.owner &&
        this.inputConfig.gridConfig.owner.disabledFunc !== undefined
      ) {
        r = this.inputConfig.gridConfig.owner.disabledFunc();
      }
      if (
        !r &&
        this.inputConfig.gridConfig &&
        this.inputConfig.gridConfig.disabledFunc !== undefined
      ) {
        r = this.inputConfig.gridConfig.disabledFunc(this.gridRow);
      }
      return r;
    } else return this.disabled;
  }

  get visible() {
    let r: boolean = true;
    if (this.inputConfig) r = this.inputConfig.visible;
    if (!r) {
      this.innerElement = null;
      this.innerElementLabel = null;
    }
    return r;
  }

  @Input()
  @CMInput()
  criptografado: boolean = false;

  @Input()
  customvalidators = [];

  @Input()
  labelEverActive: boolean = false;

  public valid: boolean = true;

  private innervalueEdit: any = "";
  get valueEdit(): any {
    return this.innervalueEdit;
  }
  @Input()
  set valueEdit(v: any) {
    if (!this.getReadOnly() && !this.disabled && this.visible) this.value = v;
  }

  protected onSetValue(oldValue: any, newValue: any) {
    if (this.inputConfig) {
      if (this.inputConfig.onSetValue !== undefined)
        this.inputConfig.onSetValue(oldValue, newValue);
    }
  }

  private innerValue: any = "";
  get value(): any {
    return this.innerValue;
  }
  @Input()
  set value(v: any) {
    let add = v !== this.innerValue;
    if (add) {
      let maxlength = this.maxlength;
      if (maxlength === 0 && this.mask) maxlength = this.mask.length;
      if (CMInputConfig.trimValue(v) !== "" && maxlength > 0) {
        add = v.length <= maxlength;
        if (!add) {
          add = CMInputConfig.trimValue(v).length <= maxlength;
          if (!add) {
            let s: string = v;
            s = s.substring(0, maxlength);
            v = s;
            add = true;
          }
        }
      }
      if (add) this._setInnerValue(v);
    }
    this.innervalueEdit = this.innerValue;
  }

  private _setInnerValue(v) {
    let old = this.innerValue;
    if (CMInputConfig.trimValue(v) !== "") {
      if (this.lower) v = v.toLowerCase();
      else if (this.upper) v = v.toUpperCase();
    }
    this.innerValue = v;
    this.checkCustomValidity();
    this.onChange.emit(this);
    this.onChangeCallback(v);
    if (this.inputConfig) {
      if (this.inputConfig.onChange !== undefined)
        this.inputConfig.onChange(this);
    }
    this.onSetValue(old, this.innerValue);
  }

  @Input()
  customStyle: string = "";

  getStyle() {
    let r = this.customStyle;
    return r;
  }

  @Input()
  customStyleLabel: string = "";
  
  getStyleLabel() {
    let r = this.customStyleLabel;
    return r;
  }

  getClass() {
    let r: string = "";
    if (!this.getReadOnly() || this.getDisabled()) r += " validate";
    return r;
  }

  isDoubleField() {
    let r = false;
    if (this.inputConfig && this.inputConfig.isDoubleField()) r = true;
    return r;
  }

  getClassButtonClear() {
    let r: string =
      "btn-floating btn-flat button-edit-clear waves-effect waves-light button-bg-white";
    return r;
  }

  getClassButton() {
    let r: string =
      "btn-floating btn-flat button-edit waves-effect waves-light button-bg-white";
    return r;
  }

  _hintButton: string = "";
  @Input()
  get hintButton() {
    return this._hintButton;
  }
  set hintButton(value) {
    this._hintButton = value;
  }

  _hintButtonClear: string = "";
  @Input()
  get hintButtonClear() {
    return this._hintButtonClear;
  }
  set hintButtonClear(value) {
    this._hintButtonClear = value;
  }

  @Input() tag: any;

  @Output()
  public onChange: EventEmitter<CMInputComponent> = new EventEmitter<CMInputComponent>();
  @Output()
  public onEnterInput: EventEmitter<CMInputComponent> = new EventEmitter<CMInputComponent>();

  checkCustomValidity() {
    let element = this.getElement();
    if (element) {
      let s: string = element.className;
      s = s.replace(" valid", "");
      s = s.replace(" invalid", "");
      let b: boolean = false;
      if (this.inputConfig) b = this.inputConfig.isPesqOnly(this.gridRow);
      let em = "";
      if (!b) {
        em = CMInputConfig.trimValue(this.errorMessage);
        b = em === "";
      }
      if (b) {
        element.setCustomValidity("");
        element.$valid = true;
        this.valid = true;
        s += " valid";
      } else {
        element.setCustomValidity(em);
        element.$valid = false;
        this.valid = false;
        s += " invalid";
      }
      element.className = s;
    }
  }

  get isEmpty() {
    return CMInputConfig.trimValue(this.value) === "";
  }

  constructor(public element: ElementRef) {}

  ngOnInit() {
    if (this.inputConfig !== null) {
      if (this.inputConfig.components.indexOf(this) == -1)
        this.inputConfig.components.push(this);
      this.caption = this.inputConfig.caption;
      this.customvalidators = this.inputConfig.customvalidators;
      this.criptografado = this.inputConfig.criptografado;
      this.readonly = this.inputConfig.readonly;
      this.customStyle = this.inputConfig.customStyle;
      this.iconButton = this.inputConfig.iconButton;
      if (this.criptografado) this.hintButton = "Mostrar / Ocultar";
      else if (this.iconButton === "search") this.hintButton = "Pesquisar";
      else if (this.inputConfig.hintButton !== undefined)
        this.hintButton = this.inputConfig.hintButton;
      this.hintButtonClear = "Limpar";
    }
    if (this.labelEverActive) {
      setTimeout(() => {
        let elementLabel = this.getElementLabel();
        elementLabel.classList.add("active");
      }, 200);
    }
  }

  getType() {
    let type = this.type;
    if (this.criptografado) {
      if (this.showPassword) type = "text";
      else type = "password";
    }
    return type;
  }

  primeiroClick: boolean = true;

  openOnClick() {
    if (this.inputConfig.gridConfig != undefined) {
      return !this.primeiroClick;
    } else {
      return true;
    }
  }

  onClick(event) {
    if (
      this.inputConfig &&
      this.inputConfig.isPesqOnly(this.gridRow) &&
      this.openOnClick()
    ) {
      this.clickButton(event);
    }
    this.primeiroClick = false;
  }

  onKeyDown(event) {
    if (!(this.disabled || this.readonly || !this.visible)) {
      if (this.inputConfig.onKeyDown) {
        this.event = event;
        this.inputConfig.onKeyDown(this);
      }
    }
  }
  
  onKeyUp(event) {
    if (!(this.disabled || this.readonly || !this.visible)) {
      if (this.inputConfig.onKeyUp) {
        this.event = event;
        this.inputConfig.onKeyUp(this);
      }
    }
  }

  onKeyPress(event) {
    if (!(this.disabled || this.readonly || !this.visible)) {
      if (this.inputConfig.onKeyPress) {
        this.event = event;
        this.inputConfig.onKeyPress(this);
      }
    }
    if (this.isDoubleField()) {
      if (
        event.key != "0" &&
        event.key != "1" &&
        event.key != "2" &&
        event.key != "3" &&
        event.key != "4" &&
        event.key != "5" &&
        event.key != "6" &&
        event.key != "7" &&
        event.key != "8" &&
        event.key != "9" &&
        event.key != "." &&
        event.key != ","
      ) {
        event.preventDefault();
        return false;
      }
    }
    if (this.inputConfig && this.inputConfig.dataType.startsWith("double")) {
      if (event.key == "." || event.key == ",") {
        if (event.target.selectionStart == event.target.selectionEnd) {
          if (event.target.value.indexOf(",") > -1) {
            event.preventDefault();
            return false;
          }
        }
      }
      if (event.key == "." || event.key == ",") {
        setTimeout(() => {
          event.target.value = event.target.value.replace(".", ",");
          if (event.target.value == ",") {
            event.target.value = "0,";
          }
        }, 10);
      }
    } else if (this.inputConfig && this.inputConfig.dataType == "integer") {
      if (event.key == "." || event.key == ",") {
        event.preventDefault();
        return false;
      }
    }
    if (this.inputConfig && this.inputConfig.isPesqOnly(this.gridRow))
      this.clickButton(event);
  }

  togglePopup(event) {
    setTimeout(() => {
      const popup = document.getElementById("myPopup_" + this.name);
      if (popup) {
        if (event.y + 100 > window.innerHeight) {
          popup.style.top = "auto";
          popup.style.bottom = "0";
        } else {
          popup.style.top = event.y;
          popup.style.bottom = "auto";
        }
        popup.style.left = event.x + 25;
        popup.classList.toggle("show");
      }
    }, 10);
  }

  clickButton(event) {
    this.togglePopup(event);
    if (this.inputConfig && this.inputConfig.dropdownValues !== undefined)
      this._dropdownValues = [];
    if (this.criptografado) {
      this.showPassword = !this.showPassword;
    }
    this.focus();
    if (this.isFocused && this.inputConfig && this.inputConfig.pesqFunc){
      this.inputConfig.pesqFunc(undefined);
    }
    else if (this.inputConfig && this.inputConfig.pesqFunc) {
      if (this.inputConfig.showButtonFunc !== undefined) {
        if (this.inputConfig.showButtonFunc(true, this.gridRow)){
          this.inputConfig.pesqFunc(undefined);
        }
      }
    }
    if (this.isFocused && this.inputConfig && this.inputConfig.addFunc) {
      this.inputConfig.addFunc(undefined);
    }
    else if (this.inputConfig && this.inputConfig.addFunc) {
      if (this.inputConfig.showButtonFunc !== undefined) {
        if (this.inputConfig.showButtonFunc(true, this.gridRow)){
          this.inputConfig.addFunc(undefined);
        }
      }
    }
  }

  clickButtonClear(event) {
    this.focus();
    if (this.isFocused && this.inputConfig && this.inputConfig.pesqFunc) {
      this.value = "";
      this.inputConfig.pesqFunc(this);
    }
    if (this.isFocused && this.inputConfig && this.inputConfig.addFunc) {
      this.value = "";
      this.inputConfig.addFunc(this);

    }
  }

  getIconButton() {
    let icone: string = "";
    if (this.iconButton instanceof Function) icone = this.iconButton();
    else icone = this.iconButton;
    if (this.criptografado) {
      if (this.showPassword) icone = "visibility_off";
      else icone = "visibility";
    }
    return icone;
  }

  showButton() {
    let r: boolean = false;
    if (!this.getDisabled()) {
      if (
        this.dropdownValues().length == 0 &&
        this.inputConfig &&
        this.inputConfig.dropdownValues !== undefined
      )
        r = false;
      else {
        if (this.criptografado) r = true;
        else r = this.iconButton !== "";
      }
    }
    if (this.inputConfig && this.inputConfig.showButtonFunc !== undefined)
      r = this.inputConfig.showButtonFunc(r, this.gridRow);
    return r;
  }

  showButtonClear() {
    let r: boolean = false;
    if (
      !this.getDisabled() &&
      this.inputConfig &&
      this.inputConfig.isPesqOnly(this.gridRow) &&
      this.inputConfig.showButtonClear
    )
      r = true;
    return r;
  }

  getCaptionText() {
    let s = "";
    if (this.caption instanceof Function) s = this.caption();
    else s = this.caption;
    return s;
  }

  getCaption() {
    let s = "";
    if (this.visiblecaption) {
      s = this.getCaptionText();
      if (this.required && !this.getDisabled()) {
        s += " *";
      }
    }
    return s;
  }

  get errorMessage() {
    if (this.inputConfig)
      return this.inputConfig.valid(this.value, !this.isFocused, this.gridRow);
    else return "";
  }

  get isFocused() {
    let r: boolean = false;
    let element: any = this.getElement();
    if (element && document.activeElement) {
      r = document.activeElement.id === element.id;
    }
    return r;
  }

  focus() {
    let element = this.getElement();
    if (element) element.focus();
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  onFocus() {
    this.onEnterInput.emit(this);
    if (this.inputConfig) {
      if (this.inputConfig.form) this.inputConfig.form.focusedComponent = this;
      if (this.inputConfig.onEnter) this.inputConfig.onEnter(this);
    }
    $(document.activeElement).select();
  }

  onBlur() {
    this.primeiroClick = true;
    this.value = CMInputConfig.trimValue(this.value);
    let padInfo = undefined;
    if (this.inputConfig) {
      padInfo = this.inputConfig.padInfo;
    } else {
      padInfo = this.padInfo;
    }
    if (padInfo !== undefined) {
      let v = CMInputConfig.pad(
        this.value,
        padInfo.size,
        padInfo.char,
        padInfo.left
      );
      if (CMInputConfig.trimValue(v) === "") this.value = "";
      else this.value = v;
    }
    if (this.inputConfig) {
      if (this.inputConfig.form)
        this.inputConfig.form.focusedComponent = undefined;
      if (
        padInfo === undefined &&
        this.inputConfig.dataType.startsWith("double")
      ) {
        let num: number = CMInputConfig.valueToFloat(this.value, 0);
        if (num > 0) {
          let dec: number = CMInputConfig.valueToInteger(
            this.inputConfig.dataType.split("(")[1].split(")")[0],
            0
          );
          if (dec > 0) this.value = num.toFixed(dec).replace(".", ",");
        }
      }
    }
    this.onTouchedCallback();
    if (this.inputConfig) {
      if (
        !this.inputConfig.isPesqOnly(this.gridRow) &&
        this.inputConfig.execPesqOnlyOnExit &&
        this.inputConfig.pesqFunc
      ){
        this.inputConfig.pesqFunc(this);
      }
      if (this.valid) {
        if (this.inputConfig.onExit) this.inputConfig.onExit(this);
      }
      if (this.inputConfig.onBlur) this.inputConfig.onBlur(this);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  getActive() {
    return !this.isEmpty;
  }

  getBottomLabel() {
    let r: string = "";
    if (this.valid) {
      if (this.inputConfig && this.inputConfig.getBottomLabelFunc !== undefined)
        r = this.inputConfig.getBottomLabelFunc();
      else r = this.bottomLabel;
    } else r = this.errorMessage;
    return r;
  }

  getActiveLabel() {
    let r: boolean = this.labelEverActive;
    if (!r) {
      if (this.inputConfig && this.inputConfig.isPesqOnly(this.gridRow))
        r = !this.isEmpty;
      else r = !this.isEmpty || this.isFocused;
    }
    return r;
  }

  setDropdownValues(event, value) {
    this.value = value;
    if (this.dropdownValues().length > 0) this.focus();
  }

  _dropdownValues = [];
  dropdownValues() {
    if (
      this._dropdownValues.length == 0 &&
      this.inputConfig &&
      this.inputConfig.dropdownValues !== undefined
    ) {
      let r: Object[] = [];
      if (this.inputConfig.dropdownValues instanceof Function)
        r = this.inputConfig.dropdownValues(this.gridRow);
      else r = this.inputConfig.dropdownValues;
      this._dropdownValues = r;
    }
    return this._dropdownValues;
  }
}
