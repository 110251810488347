// tslint:disable
import { Component, Injector } from "@angular/core";
import { ContaBancariaBrowseComponent } from "../conta-bancaria";
import { FormaPagamentoBrowseComponent } from "../formapagamento";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
} from "./../../@cmcore/component";

@Component({
  selector: "fluxodecaixa-edit",
  templateUrl: "fluxodecaixa-edit.component.html",
})
export class FluxoDeCaixaEditComponent extends CMFormEditComponent {
  getTitle() {
    return "Fluxo de Caixa - " + this.fieldByName("nduplicata");
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "fluxodecaixa";
    this.setModelConfig();
  }

  afterGetModelData() {
    console.log(this.model);
    super.afterGetModelData();

    for (let i = 0; i < this.model.movimentos.length; i++){
      //this.model.movimentos[i].duplicata = this.model.dadosmovimentos[i].duplicata;
      //this.model.movimentos[i].origem = this.model.dadosmovimentos[i].origem;
      
      if (this.model.movimentos[i] && this.model.dadosmovimentos[i]) {
        if (!this.model.movimentos[i].formapagamento) {
          this.model.movimentos[i].formapagamento = {}; // Create the formapagamento object if it doesn't exist
          Object.assign(this.model.movimentos[i].formapagamento, {
            descricao: this.model.dadosmovimentos[i].formapagamento
          });
        } 
      }

    }

    if (!this.disabledAll) this.disabledAll = this.model.podeeditar !== "Sim";
    
    console.log(this.model);
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        disabled: true,
      }),
      valor: new CMInputConfig({
        form: this,
        caption: "Valor",
        disabled: true,
      }),
      data: new CMInputConfig({
        form: this,
        caption: "Data",
        disabled: true,
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de pagamento",
        values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
        valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
      }),
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        disabled: true,
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.model, "contabancaria");
          console.log("thismodel: ", this.model);
        },
      }),       
      movimentos: new CMGridEditConfig({
        form: this,
        caption: "Movimentos",
        required: false,
        fields: {
          nduplicata: new CMInputConfig({
            form: this,
            caption: "Documento",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          origem: new CMInputConfig({
            form: this,
            caption: "Origem",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          data: new CMInputConfig({
            form: this,
            caption: "Data",
            gridConfig: new CMGridEditConfigColumn({
              width: "100",
            }),
            disabled: true,
          }),
          vmulta: new CMInputConfig({
            form: this,
            caption: "Valor multa",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vjuros: new CMInputConfig({
            form: this,
            caption: "Valor juros",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vdesconto: new CMInputConfig({
            form: this,
            caption: "Valor desconto",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vbaixa: new CMInputConfig({
            form: this,
            caption: "Valor Baixa",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          formapagamento: new CMInputConfig({
            form: this,
            disabled: true,
            caption: "Forma de pagamento",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "formapagamento.descricao");
              },
            }),
          }),
          contabancaria: new CMInputConfig({
            form: this,
            caption: "Conta Bancaria",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "contabancaria.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "contabancaria.descricao");
              },
            }),
            pesqFunc: (sender) => {
              ContaBancariaBrowseComponent.pesquisa(
                sender,
                this.modelConfig.movimentos.current,
                "contabancaria"
              );
            },
            disabled: true,
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
        },
      }),
    };
  }

  podeTerNovaBaixa: boolean = true;

  onRemoveMovimento() {
    this.podeTerNovaBaixa = false;
    this.model.novabaixa.vbaixa = this.floatToValue(0);
  }

  calc_vDevido() {
    let vdevido = this.valueToFloat(this.model.vsaldo, 0);
    vdevido += this.valueToFloat(this.model.novabaixa.vmulta, 0);
    vdevido += this.valueToFloat(this.model.novabaixa.vjuros, 0);
    vdevido -= this.valueToFloat(this.model.novabaixa.vdesconto, 0);
    this.model.novabaixa.vdevido = this.floatToValue(vdevido);
  }

  customValid() {
    let r = super.customValid();
    if (this.podeTerNovaBaixa && r === "") {
      if (this.valueToFloat(this.model.novabaixa.vbaixa, 0) > 0) {
        if (
          this.valueToFloat(this.model.novabaixa.vbaixa, 0) >
          this.valueToFloat(this.model.novabaixa.vdevido, 0)
        )
          r = "Valor da baixa não pode ser maior que o valor dedivo";
      }
    }
    return r;
  }
}
