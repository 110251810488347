// tslint:disable
import { Component, Injector } from '@angular/core';
import { FuncsGlobais } from '../funcs.globais';

import { CMInputConfig, CMFormExecComponent,
         CMFormGlobaisComponent } from './../../@cmcore/component';

@Component({
  templateUrl: "produto-gerar-codigo-de-barras.component.html",
  
})
export class ProdutoGerarCodigoDeBarrasComponent extends CMFormExecComponent {
  private sub: any;

  constructor(_injector: Injector) {
    super(_injector);
    this.functionName = "ExportarXmlsNFe/FuncaoExtra";
    this.controllerName = "nfe";
    this.downloadFiles = true;
    this.filterConfig = {
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999',
																	required: true
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999',
																	required: true
                                 }),
		}
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
	}

	innerExec() {
    super.innerExec();
		this.geraCodigoDeBarras();
	}

    geraCodigoDeBarras() {

        this.postSubscribe(
            "/api/produto/GerarCodigoDeBarrasProdutos",
            JSON.stringify({"idEmpresa": FuncsGlobais.paramEmpresa.idEmpresa}),
            (ret) => {
              if (ret["result"] === "ok") {
                CMFormGlobaisComponent.toastSucesso('Códigos de Barras Gerados com Sucesso');
              }
              CMFormGlobaisComponent.hideSpinner();
            },
            (error) => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP("gerarCodigoDeBarras", error);
            }
          );
    }
  
}
