// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { VendedorBrowseComponent } from "../../vendedor";
import {
  ClienteBrowseComponent,
  FormaPagamentoBrowseComponent,
  ProdutoBrowseComponent,
} from "../..";

@Component({
  selector: "vendas-detalhado",
  templateUrl: "vendas-detalhado.component.html",
})
export class RelatVendasDetalhadoComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoVendasDetalhado";
    this.filterConfig = {
      vendedor: new CMInputConfig({
        form: this,
        caption: "Vendedor",
        pesqFunc: (sender) => {
          VendedorBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      cliente: new CMInputConfig({
        form: this,
        caption: "Cliente",
        pesqFunc: (sender) => {
          ClienteBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de Pagamento",
        pesqFunc: (sender) => {
          FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.filter);
        },
      }),
      produto: new CMInputConfig({
        form: this,
        caption: "Produto",
        maxlength: 30,
        padInfo: { size: 30 },
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisa(sender, this.filter, "Ativos");
        },
      }),
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
      consideraprevenda: new CMInputConfig({
        form: this,
        caption: "Considera Pré-Venda em aberto",
      }),
      detalhaitens: new CMInputConfig({
        form: this,
        caption: "Demostra os itens",
      }),
      detalhagrades: new CMInputConfig({
        form: this,
        caption: "Demostra os itens",
        onExit: () => {
          console.log("this.filter: ", this.filter)
          if (this.filter.detalhagrades === 'Sim'){
            this.filter.detalhaitens = 'Sim';
          }
          else if (this.filter.detalhagrades === 'Não'){
            this.filter.detalhaitens = 'Não';
          }
        }
      }),
    };
    this.filter.dataini = CMFormRelatComponent.firstDate();
    this.filter.datafim = CMFormRelatComponent.lastDate();
    this.filter.tipoquebra1 = "Geral";
    this.filter.consideraprevenda = "Não";
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
