// tslint:disable
import { Component, Injector } from "@angular/core";
import { ContaBancariaBrowseComponent } from "../../conta-bancaria";
import { ContaContabilBrowseComponent } from "../../conta-contabil";
import { FormaPagamentoBrowseComponent } from "../../formapagamento";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "../../../@cmcore/component";
import { ClienteBrowseComponent } from "../../cliente/cliente-browse.component";
import { PessoaBrowseComponent } from "../../pessoa";
import { FuncsGlobais } from "../../funcs.globais";

@Component({
  selector: "exportar-dados",
  templateUrl: "exportar-dados.component.html",
})

export class RelatExportarDadosComponent extends CMFormRelatComponent {
  valuesOrigem = []

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  setValuesOrigem() {
    if (this.paramEmpresa.modoOperacao === "Nivel1"){
      this.valuesOrigem = [
        { value: "PreVenda", text: "Pré-Venda" },
        { value: "NFe", text: "NF-e" },
        { value: "CFe", text: "CF-e" },
        { value: "Lote", text: "Lote" },
        { value: "CupomDesconto", text: "Cupom de Desconto" },
        { value: "Todas" },
      ];
    }else {
      this.valuesOrigem = [
        { value: "PreVenda", text: "Pré-Venda" },
        { value: "NFe", text: "NF-e" },
        { value: "CFe", text: "CF-e" },
        { value: "Lote", text: "Lote" },
        { value: "CupomDesconto", text: "Cupom de Desconto" },
        { value: "NFCe", text: "NFC-e" },
        { value: 'NFeRecebimento', text: 'NF-e de Recebimento'},
        { value: "Cadastro", text: "Cadastro" },
        { value: "Todas" },
      ];
    }
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.setValuesOrigem()
    this.relatName = "ImpressaoExportarFinanceiro";
    this.filterConfig = {
      tiporelat: new CMInputConfig({
        form: this,
        caption: "Tipo de Dados",
        values: [
          { value: "ImpressaoExportarFinanceiro", text: "Financeiro" },
          { value: "ImpressaoExportarClientes", text: "Clientes" },
          { value: "ImpressaoExportarProdutos", text: "Produtos" },
          { value: "ImpressaoExportarVendas", text: "Vendas" },
        ],
        onSetValue: () => {
          console.log(this.filter);
        },
      }),
      tipoduplicata: new CMInputConfig({
        form: this,
        caption: "Tipo de Documento",
        values: [
          { value: "Pagamento", text: "Pagamento"},
          { value: "Recebimento", text: "Recebimento"},
          { value: "Todas"},
        ],
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      origem: new CMInputConfig({
        form: this,
        caption: "Origem",
        values: this.valuesOrigem,
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      dataini: new CMInputConfig({
        form: this,
        required: true,
        caption: "Data Inicial",
        mask: "99/99/9999",
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      datafim: new CMInputConfig({
        form: this,
        required: true,
        caption: "Data Final",
        mask: "99/99/9999",
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      datainiliquidacao: new CMInputConfig({
        form: this,
        caption: "Data de Baixa Inicial",
        mask: "99/99/9999",
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      datafimliquidacao: new CMInputConfig({
        form: this,
        caption: "Data de Baixa Final",
        mask: "99/99/9999",
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      somentecontasemaberto: new CMInputConfig({
        form: this,
        caption: "Somente contas em aberto",
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      demonstrarpagamentosavista: new CMInputConfig({
        form: this,
        caption: "Demonstra pagamentos a vista",
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      destinatario: new CMInputConfig({
        form: this,
        caption: "Destinatário",
        pesqFunc: (sender) => {
          PessoaBrowseComponent.pesquisa(
            sender,
            this.filter,
            "Todos",
            "destinatario"
          );
        },
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      nduplicata: new CMInputConfig({
        form: this,
        caption: "Documento",
        maxlength: 60,
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      contacontabil: new CMInputConfig({
        form: this,
        caption: "Conta Contabil",
        pesqFunc: (sender) => {
          ContaContabilBrowseComponent.pesquisa(sender, this.filter, "Categoria de Conta Contábil");
        },
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de Pagamento",
        pesqFunc: (sender) => {
          console.log('this.valuesFilers: ', this.filterConfig);
          FormaPagamentoBrowseComponent.pesquisa("contapagarreceber", sender, this.filter);
        },
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      status: new CMInputConfig({
        form: this,
        caption: "Status",
        values: [
          { value: "Abertas" },
          { value: "Baixadas" },
          { value: "Todas" },
        ],
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),

      tipopagamento: new CMInputConfig({
        form: this,
        caption: "Tipo de Recebimento",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "AVista", text: "A Vista" },
          { value: "FormaPagamento", text: "Por forma de pagamento" },
        ],
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      tipodata: new CMInputConfig({
        form: this,
        caption: "Pesquisar por",
        values: [
          { value: "Vencimento", text: "Vencimento" },
          { value: "Liquidacao", text: "Liquidação" },
          { value: "VencimentoAberto", text: "Vencimento - Liquidação Aberta" },
          { value: "Emissao", text: "Emissão" },
          { value: "VencimentoRecebimentoAberto", text: "Vencimento - Recebimento Aberto" },
          //{ value: "BomPara", text: "Bom Para" },
          { value: "EmissaoAberto", text: "Emissão - Liquidação Aberta" },
        ],
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      entsai: new CMInputConfig({
        form: this,
        caption: "Entrada/Saída",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "Entrada", text: "Recebimentos" },
          { value: "Saida", text: "Pagamentos" },
        ],
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
      tipocontapagarreceber: new CMInputConfig({
        form: this,
        visibleFunc: () => {
          return (this.filter.tiporelat === "ImpressaoExportarFinanceiro")
        },
        disabledFunc: () => {
          return (this.filter.tiporelat !== "ImpressaoExportarFinanceiro")
        }
      }),
    };

    this.filter.dataini = this.firstDateLastMonth();
    this.filter.datafim = this.lastDateNextMonth();
    // this.filter.datainiliquidacao = this.firstDateLastMonth();
    // this.filter.datafimliquidacao = this.lastDateNextMonth();
    this.filter.somentecontasemaberto = "Não";
    this.filter.demonstrarpagamentosavista = "Sim";
    this.filter.tipopagamento = "Todos";
    this.filter.tipodata = "Vencimento";
    this.filter.entsai = "Todos";
    this.filter.tipoduplicata = "Todas";
    this.filter.tipocontapagarreceber = "boleto";
    this.filter.status = "Abertas";
    this.filter.origem = "Todas";

    this.filter.tiporelat = "ImpressaoExportarFinanceiro";
  }

  exportCSV() {
    if (this.valid()) this.downloadCSV(this.filter.tiporelat, this.filter);
    console.log("method overriden");
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
