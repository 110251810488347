// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMFormPesquisaV2Component,
} from "./../../@cmcore/component";
import { PreVendaEditComponent } from "./prevenda-edit.component";
import { PreVendaRelatoriosComponent } from "../prevenda-relatorios/prevenda-relatorios.component";
import { ClienteBrowseComponent } from "./../cliente/cliente-browse.component";
import { VendedorBrowseComponent } from "./../vendedor/vendedor-browse.component";
import { FormaPagamentoBrowseComponent } from "./../formapagamento/formapagamento-browse.component";
import { FuncsGlobais } from "../funcs.globais";
import { PreVendaGerarSaidaEstoqueComponent } from "../prevenda-gerar-saida-estoque/prevenda-gerar-saida-estoque.component";

@Component({
  selector: "prevenda-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class PreVendaBrowseComponent extends CMFormBrowse2Component {

  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);
    console.log("event.code: ", event.code);
    if (event.code === 'Escape'){
      event.preventDefault();
      this.reloadCurrentRoute();
      console.log("hi there!");
      
    }
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "prevenda";
    this.formEditComponent = PreVendaEditComponent;
    this.fixedFooterFormEdit = false;
    this.buttonsExtra = 7;
    this.showPrintButton = true;
    this.showEmailButton = true;
    
    this.columns = [
      {
        field: "numero",
        caption: "#",
        width: 100,
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "data",
        caption: "Data",
        width: 130,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "cliente_nome",
        caption: "Cliente",
        filterField: "cliente.id",
        filterValue: "cliente.codigo",
        fieldBottom: "cliente.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "vendedor_nome",
        caption: "Vendedor",
        width: 250,
        filterField: "vendedor.id",
        filterValue: "vendedor.codigo",
        fieldBottom: "vendedor.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            VendedorBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "formapag_descricao",
        caption: "Forma de Pagamento",
        width: 250,
        filterField: "formapagamento.id",
        fieldBottom: "formapagamento.descricao",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.valuesFilters);
          },
        }),
      },
      { field: "nnf", caption: "Nota Fiscal", width: 200 },
      {
        field: "numero_marketplace",
        caption: "Número no Marketplace",
        filter: FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        visible: false,
      },
    ];

    if (PreVendaRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(PreVendaRelatoriosComponent.getRelatorioCustomizados());
  }
  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (PreVendaRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosPreVenda();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/prevenda/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'SegundaVia' || element['tipo'] === 'PreVenda'){
            PreVendaRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );

  }

  getTitle() {
    return "Pré-Venda";
  }
  
  openFormRelatoriosPreVenda() {
    this.beforeComponent = PreVendaRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosPreVenda");
    }, 100);
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "GerarNFe");
    } else if (index === 1) {
      this.getDireitosMenuBrowseButtonsExtra(
        index,
        "ImpressaoPreVendaReduzida"
      );
    } else if (index === 2) {
      this.getDireitosMenuBrowseButtonsExtra(index, "GerarNFCe");
    } else if (index === 3) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImprimirBoleto");
    }
    else if (index === 4) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImprimirCarne");
    }
    else if (index === 5) {
      this.getDireitosMenuBrowseButtonsExtra(index, "GerarSaidaEstoque");
    }
    else if (index === 6) {
      this.getDireitosMenuBrowseButtonsExtra(index, "GerarSaidaEstoque");
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0 || index === 1|| index === 2 || index === 3 || index === 4 || index === 5 || index === 6) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "account_balance";
    else if (index === 1) r = "receipt";
    else if (index === 2) r = "qr_code_2";
    else if (index === 3) r = "view_column";
    else if (index === 4) r = "calendar_today";
    else if (index === 5) r = "inventory";
    else if (index === 6) r = "assignment_turned_in";
    
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Gerar nota fiscal";
    else if (index === 1) r = "Imprimir Reduzido";
    else if (index === 2) r = "Gerar NFC-e";
    else if (index === 3) r = "Imprimir boleto";
    else if (index === 4) r = "Imprimir carnê";
    else if (index === 5) r = "Saída de Estoque";
    else if (index === 6) r = "Gerar Saída Total do Estoque";
    
    return r;
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      let msg: string = "<p>Confirma geração da Nota Fiscal Eletrônica?</p>";
      if (this.grid.fieldByName("nnf") !== "")
        msg +=
          '<p class="red-text"><strong>Já existe uma nota fiscal vinculada a essa Pré-Venda a mesma será sobrescrita</strong></p>';
      CMFormGlobaisComponent.showConfirmation(
        "Nota Fiscal Eletrônica",
        msg,
        () => {
          this.gerarNFe('NFe');
        }
      );
    } else if (index === 1) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImpressaoPreVendaReduzida/FuncaoExtra",
        data,
        this.controllerName
      );
    }
    else if (index === 2) {
      let msg: string = "<p>Confirma geração da Nota Fiscal de Consumidor Eletrônica?</p>";
      if (this.grid.fieldByName("nnf") !== "")
        msg +=
          '<p class="red-text"><strong>Já existe uma nota fiscal vinculada a essa Pré-Venda a mesma será sobrescrita</strong></p>';
      CMFormGlobaisComponent.showConfirmation(
        "Nota Fiscal de ConsumidorEletrônica",
        msg,
        () => {
          this.gerarNFe('NFCe');
        }
      );
    }
    else if (index === 3) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()), origem: 'Pré-Venda' };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImprimirBoleto/FuncaoExtra",
        data,
        this.controllerName
      );
    }
    else if (index === 4) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()), origem: 'Pré-Venda' };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImprimirCarne/FuncaoExtra",
        data,
        this.controllerName
      );
    }
    else if (index === 5) {
      this.openFormGerarSaidaEstoque();
    }
    else if (index === 6) {
      
      this.getObjectData('prevenda',
      [
        {'field': 'empresa', 'value': this.paramEmpresa.idEmpresa},
        {'field': 'id', 'value': this.grid.fieldByName(this.fieldNameId())},
      ],

      object => {

        let prevendaitemestoque = [];
        let baixado = (object.estoquebaixado === 'Sim');
        let idprevenda = 0;

        console.log('Object: ', object);

        object.itens.forEach((item) => {
          item.datasaida = this.date;
          item.estoquesaida = $.extend( true, [], item.estoque );

          let qtotal = 0;
          item.estoquesaida.forEach((est) => {
            qtotal += this.valueToFloat(est.quantidadeentregue);
            console.log('est.quantidadesaida: ', est.quantidade);
            est.quantidade = this.floatToValue(this.valueToFloat(est.quantidade) - this.valueToFloat(est.quantidadeentregue));
          });

          item.quantidadesaida = this.floatToValue(this.valueToFloat(item.quantidade)-this.valueToFloat(qtotal));
          //console.log('item.quantidadesaida: ', this.valueToFloat(item.quantidade)-this.valueToFloat(qtotal));

        });

        console.log('baixado: ', baixado);

        
        if (!baixado) {
          CMFormGlobaisComponent.showConfirmation(
            "Saída de Estoque",
            'Deseja realizar a saída total do estoque dos itens da Pré-Venda?',
            () => {

              idprevenda = this.valueToInteger(this.grid.fieldByName(this.fieldNameId()));

              object.itens.forEach((item) => {
                item.estoquesaida.forEach((itemestoque) => {
                  itemestoque.datasaida = item.datasaida;
                  
                  if (item.produto.usagrade1 === "Não" && item.produto.usagrade2 === "Não") {
                    itemestoque.quantidade = item.quantidadesaida;
                    prevendaitemestoque.push(itemestoque);
                  }
                  else if ((this.valueToFloat(itemestoque.quantidade) > 0 ) && !(item.produto.usagrade1 === "Não" && item.produto.usagrade2 === "Não") ){
                      prevendaitemestoque.push(itemestoque);
                  }
      
                })
              })
              
              CMFormGlobaisComponent.showSpinner();
                
              this.postSubscribe("/api/prevenda/GerarSaidaEstoque/FuncaoExtra",
                JSON.stringify({idprevenda, estoquebaixado: true, prevendaitemestoque}),(ret) => {
                  let produtos = [];
                  if (ret["data"]) produtos = ret["data"];
                  //this.filter.dados.produtos = produtos;
                  CMFormGlobaisComponent.hideSpinner();
                  CMFormGlobaisComponent.toastSucesso("Saída gerada com sucesso.");
                },
                error => {
                  CMFormGlobaisComponent.hideSpinner();
                  this.showMessageErrorHTTP('gerarSaidaEstoque', error);
                }
              );
            }
          );
        }
        else {
          CMFormGlobaisComponent.showMessageAviso("Todos os itens da Pré-Venda já foram baixados.");
        }
        //this.dados.produtos = object.itens;

        //console.log('dadosprodutos: ', this.dados.produtos);

      });

    }
  }
    
  openFormGerarSaidaEstoque() {
    this.beforeComponent = PreVendaGerarSaidaEstoqueComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formGerarSaidaEstoque");
    }, 100);
  }

  gerarNFe(tipo) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/prevenda/GerarNFe/FuncaoExtra",
      JSON.stringify({
        id: this.grid.fieldByName("id"),
        doc: tipo,
      }),
      (result) => {
        if (result["result"] === "ok") {
          let nnf = result["nnf"];
          CMFormGlobaisComponent.toastSucesso(
            "Gerado Nota Fiscal Eletrônica " + nnf
          );
          this.refresh();
        } else CMFormGlobaisComponent.showMessageAviso(result["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  getDataPrint(data) {
    data.numero = this.grid.fieldByName("numero");
    return data;
  }

  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    idCliente?: number,
    tipoPagamento?: string
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "prevenda",
        "Pesquisa de Pré-Venda",
        [
          { field: "numero", caption: "#" },
          { field: "cliente_nome", caption: "Cliente", filter: true },
          { field: "data", caption: "Data", filter: true, mask: "99/99/9999" },
        ],
        target,
        targetProp,
        "numero",
        onAfterSelect,
        undefined,
        undefined,
        {
          idcliente: idCliente,
          tipopagamento: tipoPagamento,
        }
      );
    }
  }

  static pesquisaPDV(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    idCliente?: number,
    tipoPagamento?: string
  ) {
    if (target) {
      CMFormPesquisaV2Component.defaultPesquisa(
        sender,
        "prevenda",
        "Pesquisa de Pré-Venda",
        [
          { field: "numero", caption: "#" },
          { field: "cliente_nome", caption: "Cliente", filter: true },
          { field: "data", caption: "Data", filter: true, mask: "99/99/9999" },
        ],
        target,
        targetProp,
        "numero",
        onAfterSelect,
        undefined,
        undefined,
        {
          idcliente: idCliente,
          tipopagamento: tipoPagamento,
        }
      );
    }
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  static pesquisaPreVendaTroca(
    sender: any,
    target: any,
    tipo: string,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "numero", caption: "#", filter: true},
        {
          field: "cliente_nome",
          caption: "Cliente",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        { field: "data", caption: "Data", filter: true, mask: "99/99/9999"}
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "prevenda",
        "Pesquisa de " + tipo,
        columns,
        target,
        targetProp,
        "numero",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }
}
