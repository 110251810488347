// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import { ClienteEditComponent } from "./cliente-edit.component";
import { MunicipioBrowseComponent } from "./../municipio/municipio-browse.component";
import { PessoaBrowseComponent } from "../pessoa/pessoa-browse.component";

@Component({
  selector: "cliente-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class ClienteBrowseComponent extends CMFormBrowse2Component {
  cpf: string = "";

  getTitle() {
    return "Pessoa";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "cliente";
    this.formEditComponent = ClienteEditComponent;
    this.columns = [
      {
        field: "codigo",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "nome",
        caption: "Nome",
        width: "25em",
        filter: new CMInputConfig({
          maxlength: 60,
        }),
      },
      {
        field: "fantasia",
        caption: "Fantasia",
        visible: false,
      },
      {
        field: "cpf",
        caption: "CPF",
        filter: new CMInputConfig({
          mask: "999.999.999-99",
        }),
        value: () => {
          return this.cpf;
        },
      },
      {
        field: "cnpj",
        caption: "CNPJ",
        filter: new CMInputConfig({
          mask: "99.999.999/9999-99",
        }),
      },
      {
        field: "estado",
        caption: "Estado",
        filter: new CMInputConfig({
          values: [
            { value: "" },
            { value: "AC" },
            { value: "AL" },
            { value: "AP" },
            { value: "AM" },
            { value: "BA" },
            { value: "CE" },
            { value: "DF" },
            { value: "ES" },
            { value: "GO" },
            { value: "MA" },
            { value: "MT" },
            { value: "MS" },
            { value: "MG" },
            { value: "PR" },
            { value: "PB" },
            { value: "PA" },
            { value: "PE" },
            { value: "PI" },
            { value: "RJ" },
            { value: "RN" },
            { value: "RS" },
            { value: "RO" },
            { value: "RR" },
            { value: "SC" },
            { value: "SE" },
            { value: "SP" },
            { value: "TO" },
          ],
        }),
      },
      {
        field: "municipio",
        caption: "Município",
        filterField: "ibge.codigomunicipio",
        fieldBottom: "ibge.municipio",
        filter: new CMInputConfig({
          maxlength: 7,
          pesqFunc: (sender) => {
            MunicipioBrowseComponent.pesquisa(
              this.valuesFilters.estado,
              sender,
              this.valuesFilters,
              "ibge"
            );
          },
        }),
      },
      {
        field: "tipo",
        caption: "Tipo",
        visible: false,
        filter: new CMInputConfig({
          values: [
            { value: "" },
            { value: "Cliente" },
            { value: "Fornecedor" },
            { value: "Transportadora" },
            { value: "Vendedor" },
          ],
        }),
      },
    ];

    this.route.params.subscribe((params: any) => {
      if (params.cpf) {
        this._hideShowFilters = true;
        this.cpf = params.cpf;
        this.valuesFilters["cpf"] = params.cpf;
        this.refresh();
      }
    });
  }

  afterOnPage() {
    if (this.cpf !== "") {
      setTimeout(() => {
        if (this.grid.count === 0) {
          document.getElementById("bt_browse_add").click();
        } else {
          this.selectFirst();
          setTimeout(() => {
            document
              .getElementById("bt_browse_edit_" + this.current["id"])
              .click();
          }, 10);
        }
      }, 10);
    }
  }

  modalFormEditClose() {
    super.modalFormEditClose();
    if (this.cpf !== "") {
      this.router.navigate(["/pessoa"]);
    }
  }

  // static pesquisa(
  //   sender: any,
  //   target: any,
  //   targetProp?: string,
  //   onAfterSelect?: Function
  // ) {
  //   if (targetProp == undefined) targetProp = "cliente";
  //   PessoaBrowseComponent.pesquisa(
  //     sender,
  //     target,
  //     "Cliente",
  //     targetProp,
  //     onAfterSelect
  //   );
  // }

  static pesquisa(sender: any, target: any, targetProp?: string, onAfterSelect?: Function) {
    if (targetProp == undefined) targetProp = "cliente";

    if (target) {
      const columns = [
        {
          field: "nome",
          caption: "Nome",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        {
          field: "fantasia",
          caption: "Fantasia",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        {
          field: "cpf",
          caption: "CPF",
          filter: new CMInputConfig({
            mask: "999.999.999-99",
          }),
        },
        {
          field: "cnpj",
          caption: "CNPJ",
          filter: new CMInputConfig({
            mask: "99.999.999/9999-99",
          }),
        }
      ]
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'cliente',
        'Pesquisa de Cliente',
        columns,
        target,
        targetProp,
        'codigo',
        onAfterSelect);
    }
  }

  static pesquisaPDV(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (targetProp == undefined) targetProp = "cliente";
    PessoaBrowseComponent.pesquisaPDV(
      sender,
      target,
      "Cliente",
      targetProp,
      onAfterSelect
    );
  }
}
