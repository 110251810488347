// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import {
  NFeConsignacaoEditComponent,
  NFeConsignacaoFechamentoComponent,
  PessoaBrowseComponent,
  NFeConsignacaoRelatoriosComponent,
  ClienteBrowseComponent,
} from "./../index";

@Component({
  selector: "nfe-consignacao-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class NFeConsignacaoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfeconsignacao";
    this.showPrintButton = true;
    this.buttonsExtra = 3;
    this.formEditComponent = NFeConsignacaoEditComponent;
    this.showEmailButton = true;
    this.columns = [
      {
        field: "nnf",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "dhemi",
        caption: "Data",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "pessoa_nome",
        caption: "Cliente",
        filterField: "pessoa.id",
        filterValue: "pessoa.codigo",
        fieldBottom: "pessoa.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          }
        }),
      },
      {
        field: "vendedor_nome",
        caption: "Vendedor",
        filterField: "vendedor.id",
        filterValue: "vendedor.codigo",
        fieldBottom: "vendedor.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.valuesFilters,
              "Vendedor",
              "vendedor"
            );
          },
        }),
      },
      {
        field: "status",
        caption: "Status",
        filter: new CMInputConfig({
          values: [
            { value: "Aberto" },
            { value: "Fechado" },
            { value: "Todos" },
          ],
        }),
        value: "Todos",
      },
    ];
    if (NFeConsignacaoRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(NFeConsignacaoRelatoriosComponent.getRelatorioCustomizados());
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (NFeConsignacaoRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosNFeConsignacao();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/nfeconsignacao/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'Consignacao'){
            NFeConsignacaoRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }
  
  openFormRelatoriosNFeConsignacao() {
    this.beforeComponent = NFeConsignacaoRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosNFeConsignacao");
    }, 100);
  }

  getTitle() {
    return "Consignação";
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      if (this.grid.fieldByName("status") === "Aberto") {
        this.openFormFechamentoConsignacao(this.grid.fieldByName("id"));
      } else
        CMFormGlobaisComponent.showMessageAviso(
          "Somente consignação em aberto pode ser fechada"
        );
    } else if (index === 1) {
      let data = {
        id: this.grid.fieldByName(this.fieldNameId()),
        nnf: this.grid.fieldByName("nnf"),
      };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImpressaoRetornoConsignacao/FuncaoExtra",
        data,
        this.controllerName
      );
    } else if (index === 2) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImpressaoNFeConsignacaoReduzida/FuncaoExtra",
        data,
        this.controllerName
      );
    }
  }

  idFormFechamentoConsignacao: number = 0;
  openFormFechamentoConsignacao(id) {
    this.idFormFechamentoConsignacao = id;
    this.beforeComponent = NFeConsignacaoFechamentoComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formFechamentoConsignacao");
    }, 100);
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "FechamentoConsignacao");
    }
    if (index === 1) {
      this.getDireitosMenuBrowseButtonsExtra(
        index,
        "ImpressaoRetornoConsignacao"
      );
    }
    if (index === 2) {
      this.getDireitosMenuBrowseButtonsExtra(
        index,
        "ImpressaoNFeConsignacaoReduzida"
      );
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0 || index === 1) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "assignment_return";
    else if (index === 1) r = "exit_to_app";
    else if (index === 2) r = "receipt";
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Efetuar Fechamento";
    else if (index === 1) r = "Imprimir Retorno";
    else if (index === 2) r = "Imprimir Reduzido";
    return r;
  }
}
